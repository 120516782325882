import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from '../../Pictures/logo/logo2.png';
import ScrollToSection from '../ScrollToSection'; // Import the component

const Navbar = ({ showNavLinks, toggleNavLinks }) => {
  const handleLinkClick = () => {
    // Close the menu when a link is clicked
    if (showNavLinks) {
      toggleNavLinks();
    }
  };

  return (
    <header>
      <div className="navbar">
        <Link to="/" onClick={handleLinkClick}>
          <div className="navbar-logo">
            <img src={logo} alt="logo" />
          </div>
        </Link>
        <button className={`toggle-button ${showNavLinks ? 'active' : ''}`} onClick={toggleNavLinks}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </button>
        <div className={`navbar-links ${showNavLinks ? 'active' : ''}`}>
          <ul>
            <li>
              <ScrollToSection id="apartments" onClick={handleLinkClick}>
                Apartments
              </ScrollToSection>
            </li>
            <li>
              <ScrollToSection id="local-area" onClick={handleLinkClick}>
                Town
              </ScrollToSection>
            </li>
            <li>
              <ScrollToSection id="reservation-form" onClick={handleLinkClick}>
                Contact
              </ScrollToSection>
            </li>
            <li>
              <ScrollToSection id="faq" onClick={handleLinkClick}>
                FAQ
              </ScrollToSection>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
